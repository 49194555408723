<template>
  <div class="show-event-dialog">
    <div class="show-event-dialog-box">
        <div class="show-event-dialog-box-header">
            <h2>{{event_data.title}}</h2>
            <small style="font-weight:400;">{{israeli_format_Date_string(event_data.startStr || event_data.start)}}</small>
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
            <div class="selcted_color" :style="{background:event_data.backgroundColor}"></div>
        </div>
        <div v-loading="is_pending" class="show-event-dialog-box-content">
            <p v-if="!is_edit" class="description">
                {{event_data.description}}
            </p>
            <textarea ref="edit_text_area" v-model="edited_text" v-else class="custom-textarea"></textarea>
            <div v-if="is_edit" class="when_edit slide-in-left">
                <button @click="is_edit=false" class="close">
                    <i class="material-icons close-icon">close</i>
                </button>
                <button @click="handle_confirm_edit"  class="done">
                    <i class="material-icons done-icon">done</i>
                </button>
            </div>
        </div>
        <div class="show-event-dialog-box-tools">
            <div @click="handle_approved" v-if="!is_approved" class="approved-btn">
                מאשר/ת
            </div>
            <el-tooltip
                    v-else
                    class="box-item"
                    effect="dark"
                    content="אירוע זה כבר אושר על ידך או שאינך רשאי לבצע פעולה זו!"
                    placement="top-start"
                >
                    <div class="approved-btn button-muted">
                        מאשר/ת
                    </div>
            </el-tooltip>
            <div  @click="show_images=true" v-if="has_media" class="is_images">
                <i class="material-icons image-icon">image</i>
                <div class="image-counter">{{has_media.length}}</div>
            </div>
            <div v-if="allowed_to_upload" @click="handle_open_input_file_upload" class="upload-btn">
                העלאת תמונה
            </div>
            <el-tooltip
                    v-else
                    class="box-item"
                    effect="dark"
                    content="אינך רשאי להעלות תמונה"
                    placement="top-start"
                >
                    <div class="upload-btn button-muted">
                            העלאת תמונה
                    </div>
            </el-tooltip>
        </div>
        <div class="show-event-dialog-box-footer">
            <div class="colors">
                <button v-if="user?.uid == event_data.user_uid || role >= 99" @click="handle_change_color('#3d36a1')" class="color blue"></button>
                 <el-tooltip
                    v-else
                    class="box-item"
                    effect="dark"
                    content="אינך רשאי לשנות צבע"
                    placement="top-start"
                >
                    <button data-disable class="color button-muted"></button>
                </el-tooltip>
                <button v-if="user?.uid == event_data.user_uid || role >= 99" @click="handle_change_color('#ea5455')" class="color red"></button>
                <el-tooltip
                    v-else
                    class="box-item"
                    effect="dark"
                    content="אינך רשאי לשנות צבע"
                    placement="top-start"
                >
                    <button data-disable class="color button-muted"></button>
                </el-tooltip>
                <button v-if="user?.uid == event_data.user_uid || role >= 99" @click="handle_change_color('#7367f0')" class="color purple"></button>
                <el-tooltip
                    v-else
                    class="box-item"
                    effect="dark"
                    content="אינך רשאי לשנות צבע"
                    placement="top-start"
                >
                    <button data-disable class="color button-muted"></button>
                </el-tooltip>
            </div>
            <div v-if="user?.uid == event_data.user_uid || role >= 99" class="remove_and_edit">
                <button @click="handle_edit"  class="edit">
                    <i class="material-icons edit-icon">edit</i>
                </button>
                <button @click="handle_delete_event" class="remove">
                    <i class="material-icons delete-icon">delete</i>
                </button>
            </div>
            <el-tooltip
                v-else
                class="box-item"
                effect="dark"
                content="אינך רשאי למחוק או לערוך את האירוע הזה"
                placement="top-start"
            >
                <div class="remove_and_edit">
                    <button data-disable class="edit">
                        <i class="material-icons edit-icon icon-muted">edit</i>
                    </button>
                    <button data-disable class="remove">
                        <i class="material-icons delete-icon icon-muted">delete</i>
                    </button>
                </div>
            </el-tooltip>
        </div>
        <input ref="input_file_ref" id="uploadBTN" v-show="false" type="file" @change="hande_change_file">
    </div>
    <ShowCalendarEventImages 
        v-if="show_images" 
        :images="event_data.media" 
        @close="show_images=false"
    />
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import {israeli_format_Date_string} from '../../../../Methods/Dates'
import {slide_pop_error,alert_confirm,slide_pop_successs} from '../../../../Methods/Msgs'
import upload_image_storage from '../../../../Methods/use_general_storage'
import {update_event_data_in_db,delete_event_from_db,update_media,update_approved} from '../script'
import {user,role} from '../../../../Methods/Utils'


export default {
    props:['event_data'],
    emits:['close','update_event','delete_event','submit_edit','submit_approved','submit_upload_image'],
    components:{
        ShowCalendarEventImages:defineAsyncComponent(() => import('./ShowCalendarEventImages.vue'))
    },
    setup(props,{emit}){
        const show_images = ref(false)
        const {url,uploadResizeImage_method_2,clearGPSMetadata,error:upload_error} = upload_image_storage()
        const is_pending = ref(false)
        const edit_text_area = ref()
        const edited_text = ref('')
        const is_edit = ref(false)

        const input_file_ref = ref()


        const handle_delete_event = async() => {
            try{  
                alert_confirm(`למחוק את האירוע "${props.event_data.title}" מיומן הפעילויות?`)
                .then(async res => {
                    if(res.isConfirmed){  
                        await delete_event_from_db(props.event_data.id)
                        emit('delete_event',props.event_data.id)
                    }
                })
            }catch(err){
                slide_pop_error(err.message)
            }
        }

        const handle_change_color = async(color) => {
            try{
                await update_event_data_in_db({
                    backgroundColor:color,
                    id:props.event_data.id
                })
                props.event_data.backgroundColor = color
                emit('update_event',{backgroundColor:color,id:props.event_data.id})
            }catch(err){
                slide_pop_error(err.message)
            }
        }

        const handle_confirm_edit = async() => {
            try{
                is_pending.value = true
                await update_event_data_in_db({
                    description:edited_text.value,
                    id:props.event_data.id
                })
                props.event_data.description = edited_text.value
                is_pending.value = false
                is_edit.value = false
                emit('submit_edit',{
                    description:edited_text.value,
                    id:props.event_data.id
                })
            }catch(err){
                is_pending.value = false
                slide_pop_error(err)
            }
        }

        const handle_edit = () => {
            is_edit.value=true; 
            edited_text.value=props.event_data.description
            setTimeout(()=>{
                edit_text_area.value.focus()
            },100)
        }

        const handle_approved = async() => {
            try{
                is_pending.value = true
                const approved_obj = {
                    user_uid:user.value.uid,
                    branche:user.value.branche,
                    name:user.value.display_name,
                    branche_num:user.value.branche_num
                }
                props.event_data.approved.push(approved_obj)

                await update_approved(props.event_data.id,approved_obj)
                // await update_event_data_in_db({
                //     approved:props.event_data.approved,
                //     id:props.event_data.id
                // })
                is_pending.value = false
                emit('submit_approved',{
                    id:props.event_data.id,
                    approved:props.event_data.approved
                })
            }catch(err){
                is_pending.value = false
                slide_pop_error(err.message)
            }

        }

        const handle_open_input_file_upload = () => {
            let el = document.getElementById("uploadBTN")
            if (el){
                el.click();
            }
            // input_file_ref.value.click()
        }

        const hande_change_file = async(e) => {
            const types = ['image/png','image/jpeg','image/jpg']
            try{
                is_pending.value = true
                const selected = e.target.files[0]
                let clean = null
                clearGPSMetadata(selected, async function(cleanedFile) {
                    // Now you can upload `cleanedFile` which doesn't have GPS metadata.
                    clean = cleanedFile
                    if(!clean){
                        console.log("Error");
                    }
                    if(clean && types.includes(clean.type)){
                        console.log(clean.type);
                        await uploadResizeImage_method_2(clean,`CalendarEventsImages/${user.value.branche || user.value.display_name}/${user.value.uid}/${new Date().toISOString()}`)
                        if(upload_error.value) throw new Error(`חלה שגיאה בעת העלאה! - ${upload_error.value}`)
                        const media_object = {
                            image:url.value,
                            branche: user.value.branche || 'לא צויין',
                            branche_num: user.value.branche_num || 'לא צויין',
                            user_name:user.value.display_name || 'לא צויין',
                            user_uid:user.value.uid,
                            date:new Date().toISOString()
                        }
                        props.event_data.media.push(media_object)
                        console.log('check:',props.event_data.media);

                        await update_media(props.event_data.id,media_object)
                        // await update_event_data_in_db({
                        //     media:props.event_data.media,
                        //     id:props.event_data.id
                        // })
                        slide_pop_successs('העלאת התמונה בוצעה בהצלחה')
                        is_pending.value = false
                        emit('submit_upload_image',{
                            media:props.event_data.media,
                            id:props.event_data.id
                        })
                }else{
                    throw new Error('עליך להעלות תמונה ולא קובץ מסוג אחר!')
                }
                });
            }catch(err){
                is_pending.value = false
                slide_pop_error(err.message)
            }
        }

        const has_media = ref(computed(() => {
            if(props.event_data.media.length > 0) return props.event_data.media
            return null
        }))

        const is_approved = ref(computed(() => {
            const who_can = props.event_data.authorized.filter(who => who.marked).map(who => who.branche_num)
            .includes(user.value.branche_num)
            if(who_can || role.value >= 99){
                for(const a of props.event_data.approved){
                    if(a.user_uid == user.value.uid) return true
                }
                return false
            }
            
            return true
        }))
        
        const allowed_to_upload = ref(computed(() => {
            const who_can = props.event_data.authorized.filter(who => who.marked).map(who => who.branche_num)
            if(who_can.includes(user.value.branche_num) || role.value >= 99) return true
            return false
        }))

        return{
            user,
            israeli_format_Date_string,
            handle_change_color,
            handle_delete_event,
            is_edit,
            edited_text,
            edit_text_area,
            handle_edit,
            handle_confirm_edit,
            is_pending,
            is_approved,
            handle_approved,
            hande_change_file,
            input_file_ref,
            handle_open_input_file_upload,
            has_media,
            show_images,
            role,
            allowed_to_upload
        }
    }
}
</script>

<style scoped>
    .show-event-dialog{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        color: #333;
    }
    .show-event-dialog-box{
        width: 100%;
        max-width: 500px;
        height: 80%;
        background: #fff;
        border-radius: 10px;
    }
    .show-event-dialog-box-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightgray;
    }
    .selcted_color{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        user-select: none;
    }
    .show-event-dialog-box-content{
        position: relative;
        width: 100%;
        height: calc(100% - 150px);
        padding: 5px;
        overflow: hidden;
        overflow-y: auto;
        white-space: pre-wrap;
        border-bottom: 1px solid lightgray;
    }
    .show-event-dialog-box-content::-webkit-scrollbar {
        width: 0;
    }
    .show-event-dialog-box-tools{
        width: 100%;
        height: 50px;
        border-bottom: 1px solid lightgrey;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
    }
    .show-event-dialog-box-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
    }
    .description{
        font-size: 20px;
        white-space: pre-line;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--blue);
        border-radius: 50%;
        cursor: pointer;
        user-select: none;
    }
    .colors{
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .colors > .color{
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        user-select: none;
        cursor: pointer;
        border: 0;
    }
    .color.blue{
        background: #3d36a1;
    }
    .color.red{
        background: #ea5455;
    }
    .color.purple{
        background: #7367f0;
    }
    .remove_and_edit{
        width: 20%;
        display: flex;
    }
    .remove,.edit,.done,.close{
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background: #fff;
        border-radius: 0 0 0 10px;
        cursor: pointer;
    }
    
    [data-disable]{
        cursor: default;
    }
    .delete-icon,.edit-icon,.close-icon,.done-icon,.image-icon{
        user-select: none;
        color: var(--danger);
        font-size: 30px;
    }
    .edit-icon{
        color: var(--warning);
    }
    .done-icon{
        color: var(--success);
    }
    .image-icon{
        color: var(--blue);
    }
    .icon-muted,.button-muted {
        color: #999; /* Set the color to a muted gray */
        opacity: 0.5; /* Reduce the opacity to create a muted effect */
        text-shadow: 0 0 1px #999; /* Add a subtle shadow to the icon */
    }
    .button-muted{
        background: #999;
        cursor: default !important;
    }
    .when_edit{
        position: absolute;
        width: 20%;
        height: 50px;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .slide-in-left {
	        -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
                transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
                transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }

    .custom-textarea {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding: 0 5px;
        font-size: 20px;
        white-space: pre-line;
        color: var(--blue);
        font-weight: 400;
        
    }
    .custom-textarea:focus {
        border-color: blue; /* Change the border color when focused */
        outline: none; /* Remove the default focus outline */
    }
    .approved-btn,.upload-btn{
        padding: 5px;
        height: 30px;
        background: var(--success);
        cursor: pointer;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 10px;
    }
    .upload-btn{
        background: var(--warning);
    }
    .is_images{
        position: relative;
        width: 10%;
        height: 100%;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .image-counter{
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 50%;
        background: var(--success);
        font-weight: 400;
        font-size: 12px;
    }

</style>